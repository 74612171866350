import React from "react";

import Layout from "@/components/Layout/Layout";
import Seo from "@/components/Seo/Seo";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="h-screen flex items-center justify-center">
      <h1 className="text-center text-big font-avantGarde">
        404: PAGE NOT FOUND
      </h1>
    </div>
  </Layout>
);

export default NotFoundPage;
